import React, { useEffect, useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { ContentContainer, Layout, PrivateRoute } from "../components";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { RootState } from "../state";
import { TabProps, TabsContainer } from "../components/tabs";
import { updateTabContainers } from "../state/tabsReducer";

import { UserList, UserBuyerList } from "../views/user";


interface Props {
  dispatch: Function;
  userTabs: any;
}

const TAB_CONTAINER = "user-list-tabs";

const Users = ({ userTabs, dispatch }: Props) => {

  const [tabFocus, setTabFocus] = useState<number>(0);
  const [tabUsers, setTabUsers] = useState<TabProps[]>([]);

  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
    if (props.permalink) navigate(props.permalink);
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );
  };

  useEffect(() => {
    if (! userTabs.tabContainers[TAB_CONTAINER]) {
      let initialTabContainerFocus = 0;
      let initialTabs: TabProps[] = [
        {
          title: "Internal Users",
          tabId: "user-list",
          content: <UserList />,
        },
        {
          title: "Buyer Users",
          tabId: "user-buyer-list",
          content: <UserBuyerList />,
        },
      ];

      const initialLeadsTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        }
      };

      dispatch(updateTabContainers(initialLeadsTabContainer));
    } else {
      if (userTabs.tabContainers[TAB_CONTAINER]) {
        setTabUsers(userTabs.tabContainers[TAB_CONTAINER].tabs);
        setTabFocus(userTabs.tabContainers[TAB_CONTAINER].tabFocus);
      }
    }
  }, [userTabs]);

  return (
    <ContentContainer>
      <TabsContainer
        tabs={tabUsers}
        onCloseTab={setTabUsers}
        tabFocus={tabFocus}
        onChange={handleTabChangeFocus}
        tabsContainerId={TAB_CONTAINER}
      />
    </ContentContainer>
  );
};

const UserWrapper = connect((state: RootState) => ({
  userTabs: state.tabsSection,
}), null)(Users);

export default () => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute default component={UserWrapper} path="/users/" pagetitle="LDP Users"/>
      </Router>
    </Layout>
  );
};


