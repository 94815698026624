import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { useModal, queryLoad } from "../../components";
import { Grid } from "@material-ui/core";

import { GET_USERS } from "../../common/models/users";
import { GetUsers } from "../../common/models/types/GetUsers";

import LDPUIDataTable from "../../components/LDPUIDataTable";

interface UserBuyerListProps {
  addTab: Function;
}

export const UserBuyerList = ({ addTab }: UserBuyerListProps) => {
  const [
    getAllUsers,
    { data, error, loading, refetch },
  ] = useLazyQuery<GetUsers>(GET_USERS); 

  const columnsMUI = [
    {
      name: "Email",
      label: "Email",
      options: {
        viewColumns: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "FirstName",
      label: "FirstName",
      options: {
        viewColumns: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "LastName",
      label: "LastName",
      options: {
        viewColumns: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "UserConfigRole",
      label: "Roles",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => value.map( data => data.Role.Name).join(", ")    
      },
    },
    // {
    //   name: "ContractId",
    //   label: " ",
    //   options: {
    //     viewColumns: false,
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value: any, tableMeta: any) => {
    //       return (
    //         <IconButton
    //           onClick={() => console.log("Test")
    //            //  addTab(
    //            //    `${tableMeta.rowData[1]}`,
    //            //    <ViewContract contractId={value} />
    //            //  )
    //           }
    //         >
    //           <LaunchIcon />
    //         </IconButton>
    //       );
    //     },
    //   },
    // },
  ];

  const { Modal, closeModal, openModal } = useModal();

  useEffect(() => {
    getAllUsers({variables: { where: `UserConfigBuyer.Any(UserConfigBuyerId > 0)`}});
  }, []);

  const options: any = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (    
    <Grid container spacing={1}>
      <Grid item sm={6} md={9}>
        {" "}
      </Grid>
      {/* <Grid item xs={12} sm={6} md={3}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          startIcon={<AddCircle />}
          onClick={() => {
            openModal({
              content: ( <span>UserModal</span>
               //  <ContractFormModal
               //    action={"create"}
               //    data={null}
               //    close={closeModal}
               //    refetch={refetch}
               //  />
              ),
            });
          }}
        >
          Create New
        </Button>
      </Grid> */}
      <Grid item xs={12}>
        { queryLoad([!!loading], [error]) || ( 
          <LDPUIDataTable
            ldpTableId="user-buyer-list"
            restoreFilters={true}
            title={""}
            data={data?.LDPUserConfigQueryGroup?.UserConfig}
            columns={columnsMUI}
            options={options}
          />
        )}
      </Grid>
      <Modal />
    </Grid>     
  );
};


